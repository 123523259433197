import $ from 'jquery';

export default class Form {
  constructor(Ui) {
    this.Ui = Ui;
    this.handleFormClearButton();
  }

  /**
   * Copy from ui/ui.js->handleFormClearBtn()
   */
  handleFormClearButton() {
    const $formClearBtn = $('.form-clear-btn');
    const $formClearInput = $('.form-clear-input');

    $formClearInput.each((index, element) => {
      $(element).on('blur', (event) => {
        this.inputCheckIfValue($(event.target));
      });
    });

    $formClearBtn.on('click', function() {
      $formClearInput.val('');
      $formClearInput.removeClass('has-value');
      $(this).parent().children('input[type=search]').focus();
    });
  }

  /**
   * Copy from ui/ui.js->inputCheckIfVal()
   */
  inputCheckIfValue(event) {
    const formId = event.attr('id');
    const $formField = $('#' + formId);
    const $formFieldVal = $formField.val();

    if ($formFieldVal[0] === ' ') {
      $formField.val('');
    }
    if ($formField.val() != '') {
      $formField.addClass('has-value');
    } else {
      $formField.removeClass('has-value');
    }
  }
}
