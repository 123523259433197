import $ from 'jquery';
import OffCanvas from './OffCanvas';

/**
 * Copy from nm/ui/aside.js
 */
var deviceIsSmallDown = window.matchMedia('only screen and (max-width: 739px)');
var deviceIsMediumUp = window.matchMedia('only screen and (min-width: 740px)');

class Aside {
  constructor() {
    this.init();
  }

  init() {
    document.getElementById('quicknavigation-menu').addEventListener('click', this.showMenu.bind(this));
    document.getElementById('quicknavigation-search').addEventListener('click', this.showSearch.bind(this));

    var self = this;
    $('.quicknavigation-storefinder').on('click', function(event) {
      self.showStorefinder();
    });

    deviceIsSmallDown.addListener(Aside.hideOffcanvasRight);
    deviceIsMediumUp.addListener(OffCanvas.hidePanel);
  }

  showMenu() {
    if (deviceIsSmallDown.matches) {
      OffCanvas.showPanelById('panel-offcanvas-wrapper');
    }
  }

  showSearch() {
    if (deviceIsSmallDown.matches) {
      var panelId = 'panel-search-wrapper';
      OffCanvas.showPanelById(panelId);
      $('#panel-search-field').each(function(index, field) {
        field.focus();
      });

    } else {
      this.showOffcanvasRight('search');
    }
  }

  showCustom(json, contentTemplate) {
    if (deviceIsSmallDown.matches) {
      this.loadMustacheInPanel(json, contentTemplate);
      OffCanvas.showPanelById('panel-custom-wrapper');
    } else {
      this.loadMustacheInSidebar(json, contentTemplate);
      this.showOffcanvasRight('custom');
    }
  }

  loadMustacheInSidebar(data, contentTemplate) {
    data.shopConfig = new ShopConfig();
    $('#sidebar-wrapper').html(Mustache.render(sidebarMustache, data, {
      content: contentTemplate,
    }));
    document.getElementById('sidebar-closer').addEventListener('click', this.hideOffcanvasRight);
  }

  loadMustacheInPanel(data, contentTemplate) {
    data.shopConfig = new ShopConfig();
    $('#panel-custom-wrapper').html(Mustache.render(panelMustache, data, {
      content: contentTemplate,
    }));
  }

  showOffcanvasRight(contentType) {
    document.getElementsByTagName('body')[0].classList.add(
      'sidebar-active');
    document.getElementById('sidebar-wrapper').classList.add('active');
    document.getElementById('page-shadow').classList.add('active');
  }

  hideOffcanvasRight() {
    document.getElementsByTagName('body')[0].classList.remove(
      'sidebar-active');
    document.getElementById('sidebar-wrapper').classList.remove('active');
    document.getElementById('page-shadow').classList.remove('active');

    //reset custom content panel
    $('#sidebar-wrapper').html('');
    $('#panel-custom-wrapper').html('');
  }
}

export default (new Aside());
