import $ from 'jquery';

// Expose jQuery $-shorthand, since the tracking container relies on it
window.$ = $;

initDataLayer();
injectTagCommanderContainer();

// use to trigger update $(document).trigger('updateElementTrackingDataAttributes');
$(document).on('updateElementTrackingDataAttributes', function () {
  updateElementTrackingDataAttributes();
});

function initDataLayer() {
  let siteHierarchy = $('#site-hierarchy');

  window.tc_vars = {
    env_template: pageDataLayer.siteIdentifier,
    env_work: pageDataLayer.envWork, // variable, Integ System='preprod'; live System='prod'
    env_country: pageDataLayer.language.toUpperCase(),
    env_device: getCookie('deviceType'),
    env_language: 'de', // Deutschland => nur de
    page_cat_id: pageDataLayer.pageId, // UID of page from pageDataLayer
    page_cat1: siteHierarchy.find('span:nth-child(1)').text(), // use #site-hierarchy
    page_cat2: siteHierarchy.find('span:nth-child(2)').text(), // use #site-hierarchy
    page_cat3: siteHierarchy.find('span:nth-child(3)').text(), // use #site-hierarchy
    page_cat4: siteHierarchy.find('span:nth-child(4)').text(), // use #site-hierarchy
    page_cat5: siteHierarchy.find('span:nth-child(5)').text(), // use #site-hierarchy
    page_name: siteHierarchy.find('span:last-child').text(), // use #site-hierarchy
  };
}

// Load TagCommander Container: The Data Layer needs to be filled with information before the TagCommander Container file is loaded
function injectTagCommanderContainer() {
  if (typeof window.pageDataLayer.tagCommanderContainer !== 'undefined' && window.pageDataLayer.tagCommanderContainer !== '') {
    $.getScript(window.pageDataLayer.tagCommanderContainer).done(updateElementTrackingDataAttributes);
  }
}

function updateElementTrackingDataAttributes() {
  if (typeof window.tC === 'undefined') {
    return;
  }

  $('[data-ef-tcetype="element"][data-ef-tcedata]').each((index, element) => {
    const $element = $(element);
    const tcedata = JSON.parse($element.attr('data-ef-tcedata'));

    if('kategorie' in tcedata && tcedata.kategorie === '__js__page_category_path') {
      // Update value for "kategorie" in "data-ef-tcedata" Attribute
      tcedata.kategorie = window.tC.internalvars.page_category_path;
      $element.attr('data-ef-tcedata', JSON.stringify(tcedata));
    }
  });
}

function getCookie(name) {
  const cookies = document.cookie;
  const value = '; ' + cookies;
  let parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}
