import $ from 'jquery';
import config from '../config';

export default class Footer {
  constructor() {
    this.initCountrySwitcher();
  }

  /**
   * Copy from nm/GeneralInit.js
   * NX-5185: SEO-friendly country switch
   */
  initCountrySwitcher() {
    const $countrySwitchButtons = $('.country-switch .flag');

    $countrySwitchButtons.attr('disabled', false);
    $countrySwitchButtons.on('click', (event) => {
      const $button = $(event.target);
      let targetUrl = config.homePageURL_DE;

      if ($button.hasClass('at')) {
        targetUrl = config.homePageURL_AT;
      }

      window.location = targetUrl;
    });
  }
}
