import $ from 'jquery';

/**
 * Copy from nm/ui/offcanvas.js
 */
var bodyDOM = document.getElementsByTagName('body')[0];
var doc = document;

class OffCanvas {
  constructor() {
    this.mobilePanels = {
      els: [
        'panel-offcanvas-wrapper',
        'content-wrapper',
        'panel-search-wrapper'],
      contentPanel: 1,
      currentIndex: 1,
      oldIndex: null,
    };

    this.init();
  }

  init() {
    $(document).on('click', '.panel-closer', () => {
      this.showPanel(this.mobilePanels.contentPanel);
    });
  }

  showPanelById(id) {
    this.showPanel(this.getPanelById(id));
  }

  getPanelById(id) {
    for (var i = 0; i < this.mobilePanels.els.length; i++)
      if (this.mobilePanels.els[i] == id)
        return i;
  }

  showPanel(index) {
    $('.quicknavigation-item-wrapper').removeClass('active');

    for (var i = 0; i < this.mobilePanels.els.length; i++) {
      bodyDOM.classList.remove(this.mobilePanels.els[i].replace('-wrapper', '') + '-active');
    }

    bodyDOM.classList.add('panel-active');
    bodyDOM.classList.add(this.mobilePanels.els[index].replace('-wrapper', '') + '-active');

    if (index == this.mobilePanels.currentIndex) {
      //deactivate = show content panel
      if (index != this.mobilePanels.contentPanel) {
        this.showPanel(this.mobilePanels.contentPanel);
      } else {
        bodyDOM.classList.remove('panel-active');
        bodyDOM.classList.remove(this.mobilePanels.els[this.mobilePanels.contentPanel].replace('-wrapper', '') + '-active');
      }
    } else {
      // Show panel

      //remove unnecessary classes
      var panelDOM = doc.getElementById(this.mobilePanels.els[index]);
      var panelCurrentDOM = doc.getElementById(this.mobilePanels.els[this.mobilePanels.currentIndex]);
      $(panelDOM).removeClass('slideToLeft slideToRight slideFromLeft slideFromRight animation-finished');

      if (index < this.mobilePanels.currentIndex) {
        //show
        $(panelDOM).addClass('slideFromLeft');
        //hide
        $(panelCurrentDOM).addClass('slideToRight');
      } else {
        //show
        $(panelDOM).addClass('slideFromRight');
        //hide
        $(panelCurrentDOM).addClass('slideToLeft');
      }

      this.mobilePanels.oldIndex = this.mobilePanels.currentIndex;
      var panelOldDOM = doc.getElementById(this.mobilePanels.els[this.mobilePanels.oldIndex]);
      setTimeout(function() {
        $(panelOldDOM).addClass('animation-finished');
      }, 500);

      this.mobilePanels.currentIndex = index;

      if (index == this.mobilePanels.contentPanel) {
        // If no other panel is opened and the panel is closing completely
        bodyDOM.classList.remove('panel-active');
        bodyDOM.classList.remove(this.mobilePanels.els[this.mobilePanels.contentPanel].replace('-wrapper', '') + '-active');

        // Timeout to wait for animation to be finished
        setTimeout(function() {
          // Move focus to body till it's possible to move it to related trigger button
          $('#header-wrapper').focus();
        }, 500);
      } else {
        // Move focus to current panel
        $(panelDOM).focus();
      }
    }
  }

  collapseCategoryLinks() {
    $('.nav-brandinformation').on('click', function(e) {
      e.preventDefault();

      var $this = $(this);
      $this.toggleClass('active');
      $this.next('.offcanvas-collapse').toggleClass('active');
    });
  }
}

export default (new OffCanvas());
