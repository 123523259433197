import $ from 'jquery';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import project from './campaign/Project';
import './campaign/Tracking';
import './campaign/SubmitCodeForm.mjs';

export default class Campaign {
  constructor() {
    /*
     * Session management
     */
    project.userSession.init();

    /*
     * Game
     */
    (async () => {
      if ($('#game-wrapper').length) {
        import(/* webpackChunkName: "Game" */ './campaign/Game');
      }
    })();

    /*
     * Barcode
     */
    (async () => {
      const $barcode = $('#js_tx-project-barcode');
      if ($barcode.length) {
        import(/* webpackChunkName: "Barcode" */ './campaign/Barcode');
      }
    })();

    /*
     * Additions
     */
    /* move modal to body */
    $.each($('div.modal'), function(index, modal) {
      if ($(modal).parent().get(0).tagName != 'BODY') {
        $('body').append(modal);
      }
    });

    const $teaserFullscreen = $('.ef-teaser-fullscreen');
    if ($teaserFullscreen.length) {
      import(/* webpackChunkName: "TeaserFullscreen" */ './campaign/TeaserFullscreen.mjs');
    }

    /* Open terms of use when directly accessed over hash value */
    var $termsOfUseCollapsible = $('#termsOfUse-segment-content');
    if ($termsOfUseCollapsible.length) {
      if (window.location.hash === '#termsOfUse-segment') {
        $termsOfUseCollapsible.collapse('show');
      }
      // Link from same page without reload
      const $termsOfUseLink = $('.inline-link[href$=termsOfUse-segment]');
      $termsOfUseLink.click(function() {
        $termsOfUseCollapsible.collapse('show');
      });
    }
  }
}
