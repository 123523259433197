import $ from 'jquery';

let project = (window.project === undefined) ? {} : window.project;

project.userSession = {
  cookieName: '_gRklcoiJ',
  /*
   * Utility functions
   */
  utility: {
    getCookie: function(cname) {
      var name = cname + '=',
        decodedCookie = decodeURIComponent(document.cookie),
        ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }

      return '';
    },
    setCookie: function(cname, cvalue) {
      var d = new Date();
      d.setHours(23, 59, 59, 999);
      var expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';

      return cvalue;
    },
  },
  /**
   * get prize value from session
   */
  getPrizeValue: function() {
    var cookieValue = this.utility.getCookie(this.cookieName);
    if (typeof cookieValue !== 'undefined' && cookieValue) {
      cookieValue = atob(cookieValue); //base64 decode
      cookieValue = JSON.parse(cookieValue);

      if (typeof cookieValue['prize'] !== 'undefined') {
        return cookieValue['prize'];
      }
      return undefined;
    }
    return undefined;
  },

  /**
   * Init
   */
  init: function() {
  }
};

export default project;
