// Set webpack public path. Use environment variable set in EXT:project/Configuration/TypoScript/Setup/page.typoscript
__webpack_public_path__ = window.EfConfig.javaScriptDirectoryPath || '/typo3conf/ext/project/Resources/Public/JavaScript/';

import Ui from './Ui';
import Campaign from './Campaign';

/*
 * Initialize UI
 */
new Ui();

/*
 * Initialize Campaign
 */
new Campaign();
