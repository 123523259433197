import $ from 'jquery';

import ValidatedFormView from '../form/ValidatedFormView';

const sendVoucherCodeFormSel = 'form[name=\'send-voucher-code-form\']';
const $sendVoucherCodeForm = $(sendVoucherCodeFormSel);
const FORM_PREFIX = 'tx_project_raffleajaxform[submitVoucherCodeFormData]';

class SubmitCodeForm extends ValidatedFormView {
  constructor(options) {
    super( Object.assign({
      formName: 'send-voucher-code-form',
      validations: {
        [`${FORM_PREFIX}[email]`]: ['required', 'email'],
        [`${FORM_PREFIX}[termsOfParticipation]`]: [{rule: 'checked', param: `${FORM_PREFIX}[termsOfParticipation]`}],
      },
    }, options));

    this.scrollToErrorMessage();
  }

  doSubmit(event) {
    event.preventDefault();
    const $form = this.$form;
    const ajaxUrl = $form.data('ajaxUrl');
    const $submitButton = $form.find(':submit');
    const userMailAddress = $form.find('#send-voucher-code-email').val();
    const hasNewsletterSubscriptionActivated = $form.find('#send-voucher-code-newsletter').prop('checked') || false;

    $submitButton.attr('disabled', true);

    // Send form
    $.ajax(ajaxUrl, {
      type: 'POST',
      data: $form.serializeArray(),
    }).done(function(response) {
      const $formSuccess = $(response).find('.alert-success');
      const $formHtml = $(response).find(sendVoucherCodeFormSel);

      if ($formSuccess.length) {
        $form.replaceWith('<div class="row"><div id="js-formSuccess" class="col-xs-12 col-lg-8 offset-lg-2"></div></div>');
        $('#js-formSuccess').html($formSuccess);

        if (hasNewsletterSubscriptionActivated) {
          try {
            window.tC.event.newsletter({'user_mail': userMailAddress});
          } catch (e) {
            console.log('tC event newsletter failed with: ', e);
          }
        }
      } else {
        $form.replaceWith($formHtml);
      }
      // Re-init submit handler
      new SubmitCodeForm({
        $form: $(sendVoucherCodeFormSel),
      })
      $(document).trigger('updateElementTrackingDataAttributes');
    }).fail(function(error) {
      $('#send-voucher-code-ajax-alert').addClass('form-alert').show();
      $submitButton.attr('disabled', false);
    });

    super.doSubmit();
  }
}

if ($sendVoucherCodeForm.length) {
  new SubmitCodeForm({
    $form: $sendVoucherCodeForm,
  })
}
